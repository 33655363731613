import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Image from '@nubank/nuds-web/components/Image/Image';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import styled from 'styled-components';
import { useParallax } from 'react-scroll-parallax';

const StyledTypography = styled(Typography)`
  /* stylelint-disable property-no-vendor-prefix, property-no-unknown */
  background: linear-gradient(99.92deg, #9F2DEB 18.57%, #6200A3 71.77%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`;

const StyledBox = styled(Box)`
  svg {
    transform: scale(1.4);
  }
`;

function SecurityAndTransparency() {
  const { formatMessage } = useIntl();
  const { ref } = useParallax({ speed: 3, easing: [0, -0.5, 0, -3] });

  return (
    <Box
      tag="section"
      display="flex"
      flexDirection="column"
      marginTop="32x"
      alignItems="center"
    >
      <StyledBox>

        <NuLogo variant="primary" />
      </StyledBox>

      <Box
        width="100%"
        marginTop="28x"
        paddingHorizontal={{ xs: '0', lg: '8vw', xl: '16vw' }}
      >
        <Grid
          paddingLeft="0"
          paddingRight="0"
        >

          {/* IMAGE */}

          <Grid.Child
            gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '2 / span 10' }}
            gridRow="1"
          >
            <Box ref={ref}>
              <Image
                alt="Dos personas miran su celular mientras rien"
                srcSet={{
                  xs: 'lite-version/security.jpg',
                  lg: 'lite-version/security-lg.jpg',
                }}
                width={{ xs: '100vw', lg: '100%' }}
                height={{ xs: '100%' }}
                loading="lazy"
                webp
              />
            </Box>

          </Grid.Child>

          {/* CONTENT BOX */}

          <Grid.Child
            gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '4 / span 6' }}
            gridRow="1"
          >
            <Box
              backgroundColor="#F8F8FA"
              borderRadius="12px"
              paddingTop="60px"
              paddingHorizontal="8x"
              paddingBottom="42px"
              marginHorizontal={{ xs: '8x', lg: '0' }}
              marginTop={{ xs: '64%', lg: '50%' }}
              zIndex="2"
              position="relative"
              display="flex"
              flexDirection="column"
            >
              <Typography
                variant="heading3"
                intlKey="HOME.LITE_VERSION.SECURITY_SECTION.TITLE"
                paddingBottom="8x"
                intlValues={{
                  highlight: (
                    <StyledTypography
                      tag="span"
                      intlKey="HOME.LITE_VERSION.SECURITY_SECTION.TITLE.HIGHLIGHT"
                      variant="heading3"
                      color="primary"
                    />
                  ),
                }}
                whiteSpace={{ xs: 'pre-line', md: 'normal' }}
                paddingTop={{ xs: '0', md: '8x' }}
                textAlign={{ xs: 'left', md: 'center' }}
                maxWidth={{ xs: '15ch', lg: '17ch' }}
                margin={{ xs: '0', lg: '0 auto' }}
              />

              <Typography
                variant="subtitle1"
                intlKey="HOME.LITE_VERSION.SECURITY_SECTION.SUBTITLE"
                marginBottom="8x"
                textAlign={{ xs: 'left', md: 'center' }}
                whiteSpace={{ xs: 'normal', md: 'pre-line' }}

              />

              <Link
                intlKey="HOME.LITE_VERSION.SECURITY_SECTION.LINK"
                href={formatMessage({ id: 'HOME.LITE_VERSION.SECURITY_SECTION.PATH' })}
                variant="action"
                color="primary"
                margin={{ xs: '0', md: '0 auto' }}
                alignSelf="center"
              />

            </Box>
          </Grid.Child>

          {/* ICON */}

          <Grid.Child
            gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 12' }}
            gridRow="1"
          >
            <Box
              paddingHorizontal={{ xs: '16x', md: '0' }}
              marginTop={{ xs: '54%', lg: '20%' }}
              zIndex="2"
              position="relative"
              display="flex"
              justifyContent={{ xs: 'left', md: 'center' }}
            >
              <Image
                alt="Icono de un candado"
                src="lite-version/card-lock-new.svg"
                width={{ xs: '80px', md: '120px' }}
                height={{ xs: '68px', md: '102px' }}
                webP={false}
                loading="lazy"
              />
            </Box>

          </Grid.Child>

        </Grid>
      </Box>
    </Box>
  );
}

export default SecurityAndTransparency;
